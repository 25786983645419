.goal-container {
  width: 120px;
  .goal-value {
    display: inline-block;
    width: 80px;
  }
}

.goal-editor {
  width: 120px;
  .txt-goal {
    width: 50px;
    height: 22px;
    vertical-align: bottom;
  }
}
