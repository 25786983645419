/*USERS IMAGES*/
.user-name-label{
  font-family: sans-serif;
  font-size: 70%;
  margin:3px 0px;
}
.user-name-label p{
  margin: 0px;
}
.user-image-div{
  position:relative;
}
.user-name-div{
  position: absolute;
  left: 0%;
  top: 65%;
  width: 100%;
  min-height: 16px;
  box-shadow: 0px 5px 5px #888888;
}
.user-its-onlocation{
  background: none repeat scroll 0% 0% #47BC60;
  color:#ffffff;
}
.user-its-not-onlocation{
  background: none repeat scroll 0% 0% rgb(244, 244, 179);
  color:#000;
}
.Southeast-color{
  border-color: #C30;
}
.North-color{
  border-color: #9C0;
}
.Central-color{
  border-color: #06F;
}
.West-color{
  border-color: #FF6347;
}
.Texas-color{
  border-color: #FF6347;
}
.row-location .col-sm-for-5{
padding-left: 0px;
}
.row-location .ibox-content{
  padding-left: 10px; padding-right: 10px;
}

.hired_change{
  cursor: pointer;
}

form.reset_password{
  .form-control:focus{
    border: 1px solid #e5e6e7;
  }
  .form-control-success{
    border-color: #1ab394 !important;
    background: url('/images/truck/truckg_30.png') no-repeat;
    background-position: right;
  }
  .form-control-danger{
    border-color: #ed5565 !important;
    background: url('/images/truck/truckr_40.png') no-repeat;
    background-position: right;
  }

  #validation_conf_pass_icon, #validation_pass_icon{
    position: absolute;
    right: -5px;
    top: 10px;
  }
  .validation_success{
    color: #1ab394;
  }
  .validation_error{
    color: #ed5565;
  }
  .control_success{
    border-color: #1ab394 !important;
  }
  .control_error{
    border-color: #ed5565 !important;
  }
}

