.chat-message-updated-block {

  .chat-message-header {
    padding-right: 5px;
  }

}

.chat-message-body {
  margin-top: 5px;
  padding: 5px;
}

.comment-item-avatar.pull-left img {
  margin-right: 5px;
}

.comment-item-avatar.pull-right img {
  margin-left: 5px;
}

.btn-collapse {
  .fa-plus {
    display: block;
  }
  .fa-minus {
    display: none
  }
}

.btn-collapse.opened {
  .fa-plus {
    display: none;
  }
  .fa-minus {
    display: block;
  }
}