.lock-word {
  left: 50%;
  margin-left: -505px;
  margin-top: -180px;
  position: absolute;
  top: 50%;
  z-index: 10;
}
.lock-word .first-word {
  margin-right: 145px;
}
.lock-word span {
  color: #e9e9e9;
  display: inline-block;
  font-size: 100px;
  font-weight: 600;
}
.lock-logo{
  top: 35%;
  max-width: 50vw;
  margin-top: 15vh;

  img {
    width: 100%;
  }

  @media (min-width: 768px) {
    max-width: 25vw;
  }
}
.middle-box {
  height: 400px;
  left: 50%;
  margin-left: -200px;
  margin-top: -175px;
  position: absolute;
  top: 50%;
  width: 400px;
  z-index: 100;
}
.middle-box h1 {
  font-size: 170px;
}

.wrapper .middle-box {
  margin-top: 140px;
}
.m-t {
  margin-top: 15px;
}

