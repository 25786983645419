
/* FILE MANAGER */

.file-box-manager {
  float: left;
}

.file-manager h5 {
  text-transform: uppercase;
}

.file-manager {
  list-style: none outside none;
  margin: 0;
  padding: 0;
}

.folder-list li a {
  color: #666666;
  display: block;
  padding: 5px 0;
}

.folder-list-manager li {
  border-bottom: 1px solid @border-color;
  display: block;
  padding: 0px;
}

.folder-list-manager li i {
  margin-right: 8px;
  color: #3d4d5d;
}

.category-list li a {
  color: #666666;
  display: block;
  padding: 5px 0;
}

.category-list li {
  display: block;
}

.category-list li i {
  margin-right: 8px;
  color: #3d4d5d;
}

.category-list li a .text-navy {
  color: @navy;
}

.category-list li a .text-primary {
  color: @blue;
}

.category-list li a .text-info {
  color: @lazur;
}

.category-list li a .text-danger {
  color: #EF5352;
}

.category-list li a .text-warning {
  color: #F8AC59;
}

.file-manager h5.tag-title {
  margin-top: 20px;
}

.tag-list li {
  float: left;
}

.tag-list li a {
  font-size: 10px;
  background-color: @gray;
  padding: 5px 12px;
  color: inherit;
  border-radius: 2px;
  border: 1px solid @border-color;
  margin-right: 5px;
  margin-top: 5px;
  display: block;
}

.file {
  border: 1px solid @border-color;
  padding: 0;
  background-color: #ffffff;
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
}

.file-manager .hr-line-dashed {
  margin: 15px 0;
}

.file .icon, .file .image {
  height: 100px;
  overflow: hidden;
}

.file .icon {
  padding: 15px 10px;
  text-align: center;
}

.file-control {
  color: inherit;
  font-size: 11px;
  margin-right: 10px;
}

.file-control.active {
  text-decoration: underline;
}

.file .icon i {
  font-size: 70px;
  color: #dadada;
}

.file .file-name {
  padding: 10px;
  background-color: #f8f8f8;
  border-top: 1px solid @border-color;
  font-size: 11px;
  font-weight: normal;
}

.file-name small {
  color: @text-color;
}

.corner {
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  line-height: 0;
  border: 0.6em solid transparent;
  border-right: 0.6em solid #f1f1f1;
  border-bottom: 0.6em solid #f1f1f1;
  right: 0em;
  bottom: 0em
}

a.compose-mail {
  padding: 8px 10px;
}

.mail-search {
  max-width: 300px;
}

.orders_buttons_actions{
   margin-top: 35px;
   width: 100%;
   margin-left: auto;
   margin-right: auto;
   position: absolute;
   opacity: 0;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
}

.orders_buttons_actions:hover{
  opacity: 1;
}
#widget_order_details{
  padding: 30px !important;
}
.order-manager.ibox-content {
  .file{
    margin-right: 0;
  }

  .selected{
    background-color: #eef0f6;
  }
}

.folder-list > li > a:hover {
  background-color: #f7f8fb;
}
.barcode-wrap > div {
  display: inline-block;
}
