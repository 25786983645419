.customers_list_group {
  cursor: pointer;
}

.chat-activity-list .chat-element {
  border-bottom: 1px solid #e7eaec;
}

.chat-element:first-child {
  margin-top: 0;
}

.chat-element {
  padding-bottom: 15px;
}

.chat-element,
.chat-element .media {
  margin-top: 15px;
}

.chat-element,
.media-body {
  overflow: hidden;
}

.media-body {
  display: block;
}

.chat-element > .pull-left {
  margin-right: 10px;
}

.chat-element img.img-circle,
.dropdown-messages-box img.img-circle {
  width: 38px;
  height: 38px;
}

.chat-element .well {
  border: 1px solid #e7eaec;
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px 20px;
  font-size: 11px;
  line-height: 16px;
}

.chat-element .actions {
  margin-top: 10px;
}

.chat-element .photos {
  margin: 10px 0;
}

.right.chat-element > .pull-right {
  margin-left: 10px;
}

.chat-photo {
  max-height: 180px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
}

.chat {
  margin: 0;
  padding: 0;
  list-style: none;
}

.chat li {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px dotted #B3A9A9;
}

.chat li.left .chat-body {
  margin-left: 60px;
}

.chat li.right .chat-body {
  margin-right: 60px;
}

.chat li .chat-body p {
  margin: 0;
  color: #777777;
}

.stores-list {
  max-height: calc(~"100vh - 200px");
  overflow: hidden;
}

#store_list_in_customer {
  max-height: calc(~"100vh - 350px");
  overflow-y: auto !important;
  padding-bottom: 1px;
}

/*MOBILE*/
.well.stores-list .list-group {
  max-height: 200px;
  overflow: auto;
  border-bottom: solid 1px #e7eaec;
  border-top: solid 1px #e7eaec;
}
#parent-change-div .tt-menu{
  max-height: 200px;
  overflow: auto;
}

#billed-table-customer_processing{
  margin-left: 15px;
  position: absolute;
  left:205px;
  top:236px;
  text-align:center;
}
#billed-table-customer_wrapper{
  height: 600px;
}