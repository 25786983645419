/*
Inspired by http://dribbble.com/shots/890759-Ui-Kit-Metro/attachments/97174
*/
*, *:before, *:after {
    /* Chrome 9-, Safari 5-, iOS 4.2-, Android 3-, Blackberry 7- */
    -webkit-box-sizing: border-box;
    /* Firefox (desktop or Android) 28- */
    -moz-box-sizing: border-box;
    /* Firefox 29+, IE 8+, Chrome 10+, Safari 5.1+, Opera 9.5+, iOS 5+, Opera Mini Anything, Blackberry 10+, Android 4+ */
    box-sizing: border-box;
}

.btn-nav {
    background-color: #fff;
    border: 1px solid #e0e1db;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}
.btn-nav:hover {
    color: #0088CC;
    cursor: pointer;
    -webkit-transition: color 1s; /* For Safari 3.1 to 6.0 */
    transition: color 1s;
}
.btn-nav.active {
    color: #0088CC;
    padding: 2px;
    border-top: 6px solid #0088DD;
    border-bottom: 6px solid #0088DD;
    border-left: 0;
    border-right: 0;
    box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    -webkit-transition: border 0.3s ease-out, color 0.3s ease 0.5s;
    -moz-transition: border 0.3s ease-out, color 0.3s ease 0.5s;
    -ms-transition: border 0.3s ease-out, color 0.3s ease 0.5s; /* IE10 is actually unprefixed */
    -o-transition: border 0.3s ease-out, color 0.3s ease 0.5s;
    transition: border 0.3s ease-out, color 0.3s ease 0.5s;
    -webkit-animation: pulsate 1.2s linear infinite;
    animation: pulsate 1.2s linear infinite;
}

.btn-nav.active:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 6px 6px 0;
    border-color: #e92d00 transparent;
    display: block;
    width: 0;
    z-index: 1;
    margin-left: -6px;
    top: 0;
    left: 50%;
}
.btn-nav .fa {
    padding-top: 16px;
    font-size: 40px;
}
.btn-nav.active p {
    margin-bottom: 8px;
}
/*Limit element lists height*/
.users-list, .route-list, .departments-list{
    .list-group {
        height: 200px;
        overflow: auto;
        border-bottom: solid 1px #e7eaec;
        border-top: solid 1px #e7eaec;
    }
}

.company-cropper{
    .cropper-container{
        /*width: 100% !important;
        max-height: 130px !important;*/
    }
    .img-preview.img-preview-sm{
        /*border: 1px solid #ccc;*/
    }
    .image-crop{
        height:159px;
        border: 1px solid #ccc;
    }

}

.contact-box{
    div.user-username{
        word-break: break-all;
    }
}

.contact-box-blue {
    background-color: white;
    -o-transition: color .2s ease-out, background 0.25s ease-in;
    -ms-transition: color .2s ease-out, background 0.25s ease-in;
    -moz-transition: color .2s ease-out, background 0.25s ease-in;
    -webkit-transition: color .2s ease-out, background 0.25s ease-in;
    transition: color .2s ease-out, background 0.25s ease-in;
}

.contact-box-blue:hover {
    background-color: #337ab7;
    color: white;
}

#well {
    max-height: calc(~"100vh - 325px");
    overflow-y: auto;

    .list-group {
        padding-bottom: 1px;
    }
}


@media (min-width: 768px) {
    #add-item-modal .modal-dialog {
        width: 660px;
    }
}


@-webkit-keyframes pulsate {
    50% { color: #000; }
}
@keyframes pulsate {
    50% { color: #000; }
}
@media (max-width: 480px) {
    .btn-group {
        /*display: block !important;*/
        float: none !important;
        width: 100% !important;
        max-width: 100% !important;
    }
}
@media (max-width: 600px) {
    .btn-nav .fa {
        padding-top: 12px;
        font-size: 26px;
    }
}
