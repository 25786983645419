/**
  General styles for vue
 */
[v-cloak] {
  display: none;
}
.modal-mask {
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  outline: 0;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 900px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-height: 90vh;
  overflow: scroll;
}

@media screen and (max-width: 480px) {
  .modal-container {
    max-height: 100vh;
  }
}

.modal-header h3 {
  margin: 0;
}



.modal-default-button {
  float: right;
}

/*
 * Animation for open-close modal <<transition="modal">>
 */
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/*
 * Animation for inner components <<transition name="slide-fade" mode="out-in">>
 */
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}


/*Fade transition*/
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0
}

@media screen and (max-width: 480px){
  #invoices_list .modal-container{
    max-width: 360px;
  }
}

.modified-amount{
  text-decoration: line-through !important;
  color: red;
}