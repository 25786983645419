.accounting{
  .apply_btn{
    margin: 1px 0;
  }
}
.sc_main_container{
  position: relative;
}
#shopping_cart, #shopping_cart_2{
  position: absolute;
  top: 5px;
  padding: 2px;
  z-index: 1;
  right: 15px;
  background-color: #ffffff;
  border: solid 1px #c6c6c6;
  border-radius: 4px;
  width: 175px;
  display: none;
  transition: all 0.3s ease-in-out;
  box-shadow: none;
  color: black !important;

  #sc_info_container, .sc_info_container{
    display: inline-block;
    width: 76%
  }
  #sc_total_amount, .sc_total_amount{
    display: inline-block;
    width: 45%;
    text-align: right;
  }
  .sc_number_item{
    display: inline-block;
    width: 46%;
  }
  .sc_amount_item{
    display: inline-block;
    width: 33%;
    text-align: right;
  }
  .sc_remove_item{
    display: inline-block;
    width: 16%;
  }
  #sc_action_btn, .sc_action_btn{
    display: inline-block;
    width: 40%;
  }

  #sc_invoice_container{
    display: none;
  }
  #sc_invoice_container ul, .sc_invoice_container ul{
    list-style: none;
    padding-left: 2px;
  }
  #sc_invoice_container li, .sc_invoice_container li{
    border-top: solid 1px #c6c6c6;
    padding: 2px;
    margin-top: 2px;
  }
  #sc_invoice_container li:first-child, .sc_invoice_container li:first-child{
    border-top: none;
  }
  #sc_view_items, .sc_view_items{
    width: 100%;
  }
  .sc_remove_item{
    padding: 2px;
    cursor: pointer;
  }
  #sc_checkout, .sc_checkout{
    margin-bottom: 6px;
    margin-right: 2px;
  }
  hr{
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: -2px;
    margin-right: -2px;
    border-top: 1px solid #0a6aa1;
  }
}
#shopping_cart.sc_opened, #shopping_cart_2.sc_opened{
  width: 210px !important;
  border: none;
  box-shadow: 1px 1px 4px 1px !important;
  transition: all 0.3s ease-in-out;
}

#shopping_cart_2{
  display: block;
}

.invoice_line{
  label.chk_container{
    width: 22px;
  }
}
.container_popover{
  border-color: #ffffff;
  .popover{
    box-shadow: 2px 2px 4px 0px;
  }
  .popover-title{
    font-size: 13px;
    padding: 5px 14px;

  }
  .popover-content{
    font-size: 12px;
  }
}

#process_payment_report{
  #error_alert_div {
    display: none;

  }
  .modal-container{
    padding: 0px !important;
  }
  .modal-big{
    min-width:650px;
  }
  .modal-body{
    padding: 5px 30px 5px 30px !important;
    font-size: 13px;
    text-align: left;
  }
  .modal-header{
    text-align: left;
  }
}