.invoice-header {
  border-bottom: 1px solid #ccc;
  margin-bottom: 50px;
}
.invoice-header ul {
  margin-top: 0;
  position: relative;
  text-align: right;
  top: 12px;
}
.invoice-header ul li {
  padding: 0 15px;
}
.invoice-from-to {
  margin-bottom: 50px;
}
.invoice-from-to .name {
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 5px;
}
.invoice-from-to address .contact {
  margin-top: 10px;
}
.invoice-from-to address .contact p span {
  font-weight: 700;
}
.invoice-from-to address .contact p {
  margin-bottom: 0;
}
.invoice-footer .right-col {
  position: absolute;
  right: 15px;
}
.invoice-total {
  padding-right: 10px;
}
.invoice-total .row > div {
  padding: 0;
}
.invoice-total .row > div:first-child p {
  padding-left: 10px;
}
.invoice-total .row > div:first-child p {
  padding-left: 10px;
}
.invoice-total p:last-child {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  font-size: 1.3em;
  font-weight: 700;
  padding-bottom: 10px;
  padding-top: 10px;
}
.invoice-notes p {
  font-size: 0.75em;
}
blockquote {
  border-left: 5px solid #ccc;
}
.invoice-buttons {
  margin-top: 50px;
  text-align: right;
}

#invoice_status_in_view{
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 5px;
  padding-top: 5px;
}

.invoice-from-to > .row > .alert >.close{
display: none;
}

.invoice_signature{
  margin-top: 10px;
  border-top: 2px solid #000000;
}
.invoice_note{
  min-height: 75px;
}
.invoice-from-to address{
  font-size: 12px;
}
.our_add{
  padding-left: 32px;
}
.invoice-logo{
  padding-left: 32px;
  padding-bottom: 1em;
  max-width: 210px;
}
.stores-list{
  margin-top: 10px;
}
.stores-list .label{
  cursor: pointer;
}
.stores-list .glyphicon-remove-circle{
  cursor: pointer;
  color:white;
  background-color: @btn-danger-bg;
}
.list-group-item .checkbox-inline{
  margin-right: 5px;
  margin-top: 3px;
}
.invoice-price{
  cursor: pointer;
}
.select-item{
  cursor: pointer;
}

#emails_divs{
  padding-top: 15px;
  max-height: 700px;
  overflow-y: auto;
}

.void-invoices{
  .btn.approved, .btn.declined{
    margin: 2px 0;
    vertical-align: top;
  }
}

/*Billing*/
.credit_card_panel{
  .partial_value{
    height: 22px;
  }
  .set_partial_btn, .cancel_partial_btn{
    margin-left: 4px;
  }
  .partial_btn, .reset_partial_btn{
    width: 50px;
  }
}
.changed{
  color: red;
  transition: all 0.4s ease-in-out;
}
.reseted{
  color: black;
  transition: all 0.4s ease-in-out;
}

.invoice_line_btn .btn, .btn-line .btn{
  margin: 0.5px 0;
}
/**solving*/