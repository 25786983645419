.post {
  border-radius: 2px;
  box-shadow: 0px 0px 5px 1px #c9cccd;
  margin-bottom: 10px;

  .wrap-ut {
    width: 85%;
  }
  .postinfo {
    width: 15%;
    border-left: solid 1px #dedcdc;
  }
  .comments {
    border-bottom: solid 1px #dedcdc;
    padding: 5px 0 5px 0;
    text-align: center;
  }
  .views {
    border-bottom: solid 1px #dedcdc;
    color: #9da6aa;
    font-size: 12px;
    font-family: 'Open Sans Regular', sans-serif;
    text-align: center;
    line-height: 29px;
  }
  .time {
    color: #9da6aa;
    font-size: 12px;
    font-family: 'Open Sans Regular', sans-serif;
    text-align: center;
    line-height: 29px;
  }
  .comments .commentbg {
    background-color: #3176a5;
    border-radius: 2px;
    display: inline-block;
    padding: 12px 17px;
    color: #ffffff;
    font-size: 14px;
    font-family: 'Open Sans Bold', sans-serif;
    position: relative;
    width: 50px;
  }
  .posttext {
    width: 85%;
    padding-right: 30px;
    color: #989c9e;
    font-size: 14px;
    font-family: 'Open Sans Light', sans-serif;
    line-height: 25px;
  }
  .avatar {
    //width: 37px;
    margin-left: 5px;
    width: 60%;
    padding: 10px;
    text-align: center;
    .status {
      position: absolute;
      right: 809px;
      top: 18px;
      width: 12px;
      height: 12px;
      line-height: 12px;
      border-radius: 50%;
      border: solid 2px #ffffff;
    }
    .green {
      background-color: #dedcdc;
    }
  }
  .userinfo {
    width: 15%;
    padding: 20px 0 15px 15px;
  }

  .icons {
    width: 48px;
    border-top: solid 1px #dedcdc;
    margin-top: 12px;
    padding-top: 7px;
  }
  .card-tittle{
    border-bottom: solid 1px #dedcdc;
    padding: 10px;
    cursor: pointer;
  }

  .comment_section {
    cursor: pointer;
  }
  .quater-ico-comments{
    width: 75px;
  }

}

.sidebarblock {
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 0px 5px 1px #c9cccd;
  margin-bottom: 20px;
}
.sidebarblock h3 {
  color: #363838;
  font-size: 14px;
  font-family: 'Open Sans Bold', sans-serif;
  margin: 0;
  padding: 20px;
}
.sidebarblock .divline {
  height: 1px;
  line-height: 1px;
  border-bottom: solid 1px #f1f1f1;
}
.sidebarblock .blocktxt {
  font-size: 14px;
  color: #363838;
  font-family: 'Open Sans Light', sans-serif;
}
ul, menu, dir {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 40px;
}
ul.cats li {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
  line-height: 30px;
}

.white-stripper{
  background-color: #ffffff;
}

.default-stripper{
  background-color: #f3f3f3;
}
.default-popularity{
  background-color: #908f92;
}
.warning-popularity{
  background-color: #ffa50f;
}
.warning-popularityr{
  background-color: #379b32;
}
