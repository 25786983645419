@media (min-width: @screen-md-min) {
  .col-md-for-5{
    width: 20%;
    float: left;
  }

  /**
     Table-Header Fix
   */
  .fht-table-wrapper .fht-fixed-body .fht-tbody,
  .fht-table-wrapper .fht-tbody {
    /* appearance */
    overflow-y: auto;
    overflow-x: hidden;
  }


  /* orders/ir */
  .invoice.ibox{
    .ord-ir-ctrl-btn{
      margin-top: auto;
    }
    label.btn.btn-primary.radio_button {
      width: 25%;
      margin: 0;
    }
  }

  /**
  * give style to convert tabs to accordion
  */
  .customer-view, .department-view, .user-view,.route-view, .vehicle-search-box, .tabs_collapse_convertion{
    .nav.nav-tabs li a,
    .nav.nav-tabs li.active > a:hover,
    .nav.nav-tabs li.active > a:active,
    .nav.nav-tabs li.active > a:focus {
      border-bottom-width: 1px;
      outline: none;
      padding: 10px;
    }
  }
  .megamenu.quaterdiv .lightDivider{
    background-color: #e5e5e5;
  }

/*Redefining classes for more than 992px*/
  .invoice_line_btn .btn, .btn-line .btn{
    margin: auto;
  }
}

