span.twitter-typeahead {
  width: 100%;

  .tt-menu {
    min-width: 100% !important;
    &:extend(.dropdown-menu);
  }
  .tt-dropdown-menu {
    &:extend(.dropdown-menu);
  }
  .tt-suggestion{
    padding: 10px !important;
    cursor: pointer;
  }
  .tt-suggestion:hover{
    background-color: @btn-primary-bg;
    color: #ffffff;
  }
  .tt-suggestion > p {
    &:extend(.dropdown-menu > li > a);
    &:hover,
    &:focus {
      &:extend(.dropdown-menu > .active > a);
    }
    // Provide class for links that match alerts
    .alert-link {
      font-weight: @alert-link-font-weight;
    }
    .tt-suggestion.tt-cursor > p {
      &:extend(.dropdown-menu > .active > a);
    }
    .input-group & {
      display: block !important;
      .tt-dropdown-menu {
        top: 32px !important;
      }
    }
    .input-group.input-group-lg & {
      .tt-dropdown-menu {
        top: 44px !important;
      }
    }
    .input-group.input-group-sm & {
      .tt-dropdown-menu {
        top: 28px !important;
      }
    }
  }
}

