@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 @sidebar-width;
    min-height: 1200px;
  }

  .navbar-static-side {
    z-index: 101;
    position: absolute;
    width: @sidebar-width;
  }

  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks,
  .navbar-top-links .dropdown-alerts {
    margin-left: auto;
  }
}

@media (max-width: 768px) {

  #page-wrapper{
    position: inherit;
    margin: 0 0 0 0px;
    min-height: 1000px;
  }

  .body-small .navbar-static-side {
    display: none;
    z-index: 101;
    position: absolute;
    width: 70px;
  }

  .body-small.mini-navbar .navbar-static-side {
    display: block;
  }

  .lock-word {
    display: none;
  }

  .navbar-form-custom {
    display: none;
  }

  .navbar-header {
    display: inline;
    float: left;
  }

  .sidebard-panel {
    z-index: 2;
    position: relative;
    width: auto;
    min-height: 100% !important;
  }

  .sidebar-content .wrapper {
    padding-right: 0px;
    z-index: 1;
  }

  .fixed-sidebar.body-small .navbar-static-side {
    display: none;
    z-index: 101;
    position: fixed;
    width: @sidebar-width;
  }

  .fixed-sidebar.body-small.mini-navbar .navbar-static-side {
    display: block;
  }

}

@media (max-width: 350px) {

  .timeline-item .date {
    text-align: left;
    width: 110px;
    position: relative;
    padding-top: 30px;
  }

  .timeline-item .date i {
    position: absolute;
    top: 0;
    left: 15px;
    padding: 5px;
    width: 30px;
    text-align: center;
    border: 1px solid @border-color;
    background: #f8f8f8;
  }

  .timeline-item .content {
    border-left: none;
    border-top: 1px solid @border-color;
    padding-top: 10px;
    min-height: 100px;
  }

  .nav.navbar-top-links li.dropdown {
    display: none;
  }

}

