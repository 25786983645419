#on-location-map{
  ul{
    display: block;
  }

  .location-filter li{
    border-bottom: 1px solid #e7eaec;
    color: #666666;
    display: block;
    padding: 3px;
  }
  .location-filter li a{
    color: #666666;
    display: block;
    padding: 5px 0;
  }
  .sub-filter{
    padding-left: 10px;
  }
  .sub-filter li:hover{
    background-color: #f3f5fb;
    border-bottom: solid 1px #0a0b0c;
  }
  .location-filter li:hover{
    background-color: #f3f5fb;
    border-bottom: solid 1px #0a0b0c;

  }
  .gm-style-iw{
    max-height: 200px;
    max-width: 280px;

  }
  .option-location-selected{
    background-color: #f3f5fb;
    border-bottom: solid 1px #0a0b0c;
  }
  .option-location-selected:hover{
    background-color: inherit;
    border-bottom: none;
  }
  #legend {
    font-family: Arial, sans-serif;
    padding: 10px 20px 10px 20px;
    margin: 10px;
    background-color: rgba(127,133,126,0.9);
    color: white;
  }
  #Control1 {
    font-family: Arial, sans-serif;
    padding: 10px 20px 10px 20px;
    margin: 3px;
    background-color: rgba(127,133,126,0.9);
    color: white;
  }

  #legend h3 {
    margin-top: 0;
  }
  #legend img {
    vertical-align: middle;
  }
  .region-color{
    width: 30px;
    height: 15px;
    display:block;
    float: left;
    margin-left: 10px;
  }
  .northeast{
    background-color: #ffffff;
  }
  .west{
    background-color: rgba(255,0,0,0.35);
  }
  .mountain{
    background-color: rgba(255,240,0,0.5);
  }
  .texas{
    background-color: rgba(0,255,222,0.35);
  }
  .central{
    background-color: rgba(255,78,0,0.5);
  }
  .gulf{
    background-color: rgba(54,255,0,0.35);
  }
  .mid-atlantic{
    background-color: rgba(5,0,113,0.35);
  }
  .region-name{
    float: left;
    margin-left: 5px;
    cursor: pointer;
  }
  .regions-container{
    margin-bottom: 5px;
  }
  .search-choice{
    color:#242425;
    background-image: none;
    border:none;
  }

}