.contact-box-equipment {
  min-height: 210px;
}
.equipments-feed{
    max-height: 450px;
    overflow-y: auto;
}
.file-name-title{
  border-top: 0px !important;
  margin-top: -5px;
}

.opened > a {
  padding: 6px 15px !important;
}

.dropdown-custom {
  left: -100%;
}
#google_maps{
  height: 900px;
}
#dashboard_info_equipment{
  .contact-box{
    h4.assign{
      height: 85px;
      vertical-align: middle;
      display: table-cell;
      width: 130px;
    }
  }
}