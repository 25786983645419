@media (min-width: @screen-lg-min) {
  .col-ls-for-5{
    width: 20%;
    float: left;
  }
  .menu-search-div{
    padding-right: 0px;
    height: 30px;
    margin: -20px 0px 0px;
  }
  .contact-box{
    padding: 20px;
    /*min-height: 185px;*/
  }
  .invoice-heading .widget{
    padding: 15px 20px;
  }

}

