#payment_logs_container{
    #payment-log-data-table_processing{
        position: absolute;
        top: 250px;
        left: 420px;
        text-align: center;
    }
    .table-in-payment-logs{
        position: relative;
        min-height: 750px;
        height:100%;
    }
}