.setting_security {
    #validation_conf_pass_icon,#validation_pass_icon {
      position: absolute;
      right: -5px;
      top: 10px;
    }
    #validation_conf_pass_icon, #validation_pass_icon {
      position: absolute;
      right: -5px;
      top: 10px;
    }
    .validation_success {
      color: #1ab394;
    }
    .validation_error {
      color: #ed5565;
    }
    .control_success {
      border-color: #1ab394 !important;
    }
    .control_error {
      border-color: #ed5565 !important;
    }
}

#well {
  max-height: calc(~"100vh - 325px");
  overflow-y: auto;

  .list-group {
    padding-bottom: 1px;
  }
}

#invalid-login-container {
  #invalid_login_table_processing {
    position: absolute;
    left: 255px;
    top: 317px;
    text-align: center;
  }
  #tab-3 {
    height: 550px;
  }
}
