/* MAILBOX */
.table-mail{
  margin-bottom: 0px;
}
table.table-mail tr td {
  padding: 12px;
}
.table-mail .check-mail {
  padding-left: 20px;
}
.table-mail .mail-date {
  padding-right: 20px;
}
.star-mail, .check-mail {
  width: 40px;
}

.unread td:first-child a, .unread td {
  font-weight: 600;
  color: inherit;
}
.unread .edit_topic_description{
  font-weight: normal;
  color: inherit;
  font-size: 11px;
}
.read td a, .read td {
  font-weight: normal;
  color: inherit;
}

.unread td {
  background-color: #f9f8f8;
}

.mail-box {
  background-color: #ffffff;
  border: 1px solid #e7eaec;
  border-top: 0;
  padding: 0px;
}
.mail-box-header {
  background-color: #ffffff;
  border: 1px solid #e7eaec;
  padding: 30px 20px 20px 20px;
}
.mail-box-header h2 {
  margin-top: 0px;
}
.mailbox-content .tag-list li a {
  background: #ffffff;
}
.mail-body {
  border-top: 1px solid #e7eaec;
  padding: 20px;
}
.mail-text {
  border-top: 1px solid #e7eaec;
}
.mail-text .note-toolbar {
  padding: 10px 15px;
}
.mail-body .form-group {
  margin-bottom: 5px;
}
.mail-text .note-editor .note-toolbar {
  background-color: #F9F8F8;
}
.mail-attachment {
  border-top: 1px solid #e7eaec;
  padding: 20px;
  font-size: 12px;
}
.mailbox-content {
  background: none;
  border: none;
  padding: 10px;
}
.attachment {
  .att-box .image{
    border: 1px solid #ccc;
    position: relative;
    display: table;
    width: 100%;
    height: 120px;
  }
  .att-box .gallery{
    display: table-cell;
    vertical-align: middle;
  }
  .att-box .image img{
    max-height: 120px;
    margin: 0 auto;
  }
}
#rich_text_description{
  a{
    font-weight: bold;
    text-decoration: underline;
  }

  .img-responsive{
    max-width: 100%;
    height: auto !important;
  }


}