.apply_image_size {
  display: block;
  height: 100%;
}

.center-fit {
  max-width: 100%;
  max-height: 60vh;
  margin: auto;
}

.w-100 {
  width: 100% !important;
}

.p-0 {
  padding: 0;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-25 {
  padding: 25px;
}

.p-50 {
  padding: 50px;
}

.padding_0 {
  padding: 0;
}

.padding_5 {
  padding-top: 5px;
}

.padding_10 {
  padding-top: 10px;
}

.padding_15 {
  padding-top: 15px;
}

.padding_25 {
  padding-top: 25px;
}

.padding_50 {
  padding-top: 50px;
}

.paddingL_0 {
  padding-left: 0px;
}

.paddingL_5 {
  padding-left: 5px;
}

.paddingL_10 {
  padding-left: 10px;
}

.paddingL_15 {
  padding-left: 15px;
}

.paddingL_25 {
  padding-left: 25px;
}

.paddingL_50 {
  padding-left: 50px;
}

.paddingR_0 {
  padding-right: 0px;
}

.paddingR_5 {
  padding-right: 5px;
}

.paddingR_10 {
  padding-right: 10px;
}

.paddingR_15 {
  padding-right: 15px;
}

.paddingR_25 {
  padding-right: 25px;
}

.paddingR_50 {
  padding-right: 50px;
}

.paddingB_0 {
  padding-bottom: 0px;
}

.paddingB_5 {
  padding-bottom: 5px;
}

.paddingB_10 {
  padding-bottom: 10px;
}

.paddingB_15 {
  padding-bottom: 15px;
}

.paddingB_25 {
  padding-bottom: 25px;
}

.paddingB_50 {
  padding-top: 50px;
}

.right-5 {
  margin-right: 5px;
}

.right-15 {
  margin-right: 15px;
}

.right-25 {
  margin-right: 25px;
}

.top-5 {
  margin-top: 5px;
}

.top-15 {
  margin-top: 15px;
}

.top-20 {
  margin-top: 20px;
}

.top-25 {
  margin-top: 25px;
}

.bot-5 {
  margin-bottom: 5px;
}

.bot-15 {
  margin-bottom: 15px;
}

.bot-20 {
  margin-bottom: 20px;
}

.bot-25 {
  margin-bottom: 25px;
}

.min-h-200 {
  min-height: calc(100vh - 200px);
}

.edit {
  cursor: pointer;
}

.edit-button {
  margin-top: 5px;
}

.cash_table {
  text-align: right;
}

.border-solid {
  border: .15em solid;
}

.table-for-jedit > tbody > tr > td, .table-for-jedit > tbody > tr > th {
  padding-top: 15px;
  padding-bottom: 15px;
}

a, a:visited, a:focus, a:active, a:hover {
  outline: 0 none !important;
}

//Blue Table Header
.table-blue > thead > tr > th {
  background-color: @panel-primary-heading-bg !important;
  color: #ffffff;
}

.table-blue > tbody > tr.clickable {
  cursor: pointer;
}

.table-blue > tbody > tr > th.subheading {
  background-color: @panel-primary-heading-bg !important;
  color: #ffffff;
}

.table-blue > tbody > tr > td.subcontent {
  background-color: @well-bg !important;
}

/*for 5 columns CODE*/
.col-xs-for-5,
.col-md-for-5,
.col-sm-for-5,
.col-lg-for-5 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-for-5 {
  width: 49%;
  float: left;
}

.container {
  padding-left: 5px;
  padding-right: 5px;
}

.table-sm {
  font-size: 11px !important;
}

.radio_button.active {
  background-color: @btn-danger-bg;
  border-color: @btn-danger-border;
  color: #ffffff;
}

.bg-success {
  .bg-variant(@state-success-bg);
}

.bg-danger {
  .bg-variant(@state-danger-bg);
}

.minute {
  min-width: 85px;
}

.hour, .ampm {
  min-width: 65px;
}

.hand {
  cursor: pointer;
}

.table-condensed-plus {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: 2px;
        font-size: 83%;
      }
    }
  }
}

.aux_form_control {
  background: #ffffff none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  font-size: 14px;
  height: 34px;
  line-height: 1.42857;
  padding: 6px 12px;
}

.aux_form_control:focus {
  border-color: #1c84c6;
}

.menu-search-div {
  padding-right: 0px;
  height: 30px;
  margin: -20px 0px 10px;
  position: fixed;
  right: 0px;
}

.search_website_div {
  max-width: 250px !important;
}

.chosen-container.chosen-container-single, .chosen-container.chosen-container-multi {
  width: 100% !important;
}

.combodate.input-group .chosen-container.chosen-container-single {
  width: auto !important;
}

hr.mobile-separator {
  margin: 5px 0;
}

/*For all measures*/
.top-xs {
  margin-top: 5px;
}

.top-sm {
  margin-top: 10px;
}

.top-md {
  margin-top: 15px;
}

.top-lg {
  margin-top: 20px;
}

.left-xs {
  margin-left: 5px;
}

.left-sm {
  margin-left: 10px;
}

.left-md {
  margin-left: 15px;
}

.left-lg {
  margin-left: 20px;
}

/*MOBILE*/
.top-mobile-xs {
  margin-top: 5px;
}

.top-mobile-sm {
  margin-top: 10px;
}

.top-mobile-md {
  margin-top: 15px;
}

.top-mobile-lg {
  margin-top: 20px;
}

.text-mobile-center {
  text-align: center;
}

.center-mobile {
  margin: 0 auto;
}

/*PRINT*/
.top-print-xs, .top-print-sm, .top-print-md, .top-print-lg {
  margin-top: 0;
}

/*NOTIFICATIONS*/
.jq-toast-wrap {
  min-width: 350px;
  max-width: 450px;
}

.jq-toast-single {
  font-size: inherit;
}

.jq-toast-single h2 {
  font-weight: bold;
}

.close-jq-toast-single {
  font-size: 26px;
}

.jq-toast-loader.jq-toast-loaded {
  top: 0 !important;
  border-radius: 4px 0 0 0;
  background-color: #ffffff !important;
  opacity: 0.4;
}

.jq-icon-success, .icon-success {
  color: #FFFFFF;
  background-color: #18a689;
  border-color: #18a689;
}

.jq-icon-warning, .icon-warning {
  color: #FFFFFF;
  background-color: #f7a54a;
  border-color: #f7a54a;
}

.jq-icon-info, .icon-info {
  color: #FFFFFF;
  background-color: #21b9bb;
  border-color: #21b9bb;
}

.jq-icon-error, .icon-error {
  color: #FFFFFF;
  background-color: #ed5565;
  border-color: #ed5565;
}

/**
* give style to convert tabs to accordion
*/
.customer-view, .department-view, .user-view, .route-view, .vehicle-search-box, .tabs_collapse_convertion {
  .panel-heading {
    padding: 0
  }

  .panel-heading a {
    display: block;
    padding: 10px;
  }

  .panel-heading a.collapsed {
    background: #fff
  }

  .panel-heading a {
    /*background: #f7f7f7;
    border-radius: 5px;*/
  }

  .nav.nav-tabs li a,
  .nav.nav-tabs li.active > a:hover,
  .nav.nav-tabs li.active > a:active,
  .nav.nav-tabs li.active > a:focus {
    border-bottom-width: 0px;
    outline: none;
  }

  .nav.nav-tabs li a {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .tab-pane {
    background: #fff;
    padding: 10px;
    margin-top: -1px;
  }

  .visible-tabs {
    display: none;
  }

  h4.panel-title {
    margin-top: 0;
    padding: 1px;
  }

  .hidden-tabs h4.panel-title {
    margin-bottom: 0px;
  }

}

.nav.nav-tabs {


  &.stale {
    li {
      a {
        border-radius: 0;
      }

      &:not(.active) {
        a {
          background-color: #e6e6e6;
          color: #676a6c;
        }
      }
    }

    + .tab-content {
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }
  }
}

.filter-generic-control-style {
  form select {
    display: none;
  }

  form .form-control {
    height: 28px;
    border-radius: 3px;
    border-color: #aaa;
  }

  /*  form .form-control:focus{
      border-color: #1c84c6;
    }*/

  .input-group-addon.btn {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-color: #aaa;
  }
}

.input-group-btn {

  &.spaced {
    button {
      margin-left: .5em !important;
      border-radius: 3px !important;
    }
  }
}

.select2-container--default .select2-selection--single {
  height: 34px;
  border: 1px solid #e5e6e7;

  .select2-selection__arrow {
    height: 34px;
  }
}

.modal-body {
  padding: 20px;
}

.tab-pane {

  .ibox-title {
    border-top: none;
  }
}

.ibox-title {
  .nav-tabs {
    border-bottom: none;
  }
}

.ibox-content {

}

.invoice-buttons {
  button, a {
    padding: 6px 6px;
  }
}

body.modal-open {
  overflow: visible;
  position: relative;
  width: 100%;
}

.modal-backdrop.fade.in {
  position: fixed;
}

.table-ajax-height {
  min-height: 450px;
}

.pac-container {
  z-index: 9999999;
}

.loading-overlay {
  position: absolute;
  background: white;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  opacity: .8;

  i {
    position: relative;
  }
}

.image-box {
  border: 2px solid;
}

.image-box > img {
  opacity: 0.2;
}

.image-box-success {
  border-color: #18a689;
  opacity: 1;
}

.image-box-success > img {
  opacity: 1;
}

