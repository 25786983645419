
#message-center-notification{
    @media only screen and (min-width: 500px) {
        /* For desktop devices: */
        .table-responsive {
            overflow-x:hidden ;
        }
    }
    .note-editor{
        margin-bottom: 15px;
        border-radius: 3px;
        resize: none;
        border: 1px solid #a9a9a9;
    }
    .read{
      color:#337ab7;
    }
    .category-list{
        padding-left:15px;
    }
    .option-selected {
        color: darkgreen;
    }
    .category-list li i.option-selected{
        color: darkgreen;
        margin-right: 8px;
    }
    .category-list li a.option-selected {
        color: darkgreen;
        display: block;
        padding: 5px 0;
        text-decoration: underline;
    }
    .container-message-summernote{
        padding-right:30px;
    }
    .folder-list li{
        cursor: pointer;
        padding: 5px 0 5px 0;
    }


    .sticky-tack{
        color:#05054e;
    }

    .folder-selected{
        background-color: #f6f6fa;
    }
    .folder-list li.folder-selected{
        border-bottom:1px solid #aaaaad;
    }
    #notifications_table_news_body td {
        padding: 7px;;
    }

    #notifications-table-data_processing {
        margin: 25px;
    }

    #notifications-table-data_processing{
        display: block;
        position: absolute;
        top: 182px;
        left: 216px;
        text-align: center;
    }
    .table-messages-notifications{
        min-height: 575px;
    }

    .folder-list > li:hover {
        background-color: #f7f8fb;
    }
}

div.contenedor{
    margin-bottom: 25px;
}
textarea.notification{
    margin: 0px;
    width: 830px;
    height: 231px;
    border: none;
    resize: none;
}
.options{
    background: #faf0e67a;
    border-top: solid 1px #bdbdbd14;
    border-bottom: solid 1px #bdbdbd14;
    margin-left: 137px;
}
/*.title-notification{
    margin-left: 8px;
    margin-top: 31px;
    padding-top: 30px;
}*/
.a-list{
    display: block;
    color: black;
    text-align: center;
    padding-left: 20px;
    text-decoration: none;
}
.option-selected{
    color: #379b32;
}
.folder-selected {
    border-bottom: 1px solid #005690 !important;
    background-color: #f1f8fd;
}
.folder-list li:hover{
    background-color: #f1f8fd;
}
.loading_container{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 90;
    top: 0;
    left: 0;
    background: rgba(230, 247, 255,0.5);
    margin-left: 15px;
    color: black;
}
table.table-mail tr td {
    padding:7px;
}
.drag-lines{
    border-bottom: #89b8b8 dashed 3px !important;
}

