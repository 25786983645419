.SearchInput {
  width: 100%;
  padding: 1.5em 1em;
  font-size: 1em;
  outline: 0;
  border: 5px solid #41B883;
}
.container-result-menu{
  margin-top: 3px;
  position: absolute;
  width: 89%;
  z-index: 100;
}
.Results {
  margin: 2px 0 0 0;
  padding: 2px;
  text-align: left;
  position: relative;
  background: rgb(255, 255, 255);
  border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  border: 1px solid #58a8b6;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-shadow: 0px 16px 45px -6px rgba(0,0,0,0.6);
  -moz-box-shadow: 0px 16px 45px -6px rgba(0,0,0,0.6);
  box-shadow: 0px 16px 45px -6px rgba(0,0,0,0.6);
}
.Results p{
  margin:2px 0 0 0;
}
.Results li {
  margin: 0;
  padding: 1em;
  list-style: none;
  width: 100%;
  transition: ease-in-out 0.5s;
  cursor: pointer;
}
.Results li:hover{
  background: #0a6aa1;
  margin-left: 1px;
  color: white;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}