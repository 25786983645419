/* DASHBOARD */
.dashboard-header {
  border-top: 0;
  padding: 20px 20px 20px 20px;
}

.dashboard-header h2 {
  margin-top: 10px;
  font-size: 26px;
}

.fist-item {
  border-top: none !important;
}

.statistic-box {
  margin-top: 40px;
}

.dashboard-header .list-group-item span.label {
  margin-right: 10px;
}

.dropdown-menu {
  padding:5px;
  min-width: 300px;
}

.list-group.clear-list .list-group-item {
  border-top: 1px solid @border-color;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  padding: 10px 0;
}

ul.clear-list:first-child {
  border-top: none !important;
}


/*FEED ALSO USE IN PROFILE*/
.feed-activity-list .feed-element {
  border-bottom: 1px solid @border-color;
}
.feed-element:first-child {
  margin-top: 0;
}
.feed-element {
  padding-bottom: 15px;
}
.feed-element, .feed-element .media {
  margin-top: 15px;
}
.feed-element, .media-body {
  overflow: hidden;
}
.feed-element > .pull-left {
  margin-right: 10px;
}
.feed-element img.img-circle, .dropdown-messages-box img.img-circle {
  width: 38px;
  height: 38px;
}
.feed-element .well {
  border: 1px solid @border-color;
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px 20px;
  font-size: 11px;
  line-height: 16px;
}
.feed-element .actions {
  margin-top: 10px;
}
.feed-element .photos {
  margin: 10px 0;
}
.feed-photo {
  max-height: 180px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
}

.lineChart{
  height: 85px;
}

/* Intimeline */
.lastest-events,.lastest-news{
  height: 450px;
  overflow-y: auto;
}

.timeline-item .date {
  text-align: left;
  position: relative;
  padding-top: 30px;
}

.timeline-item .date i {
  position: absolute;
  top: 0;
  left: 15px;
  padding: 5px;
  width: 30px;
  text-align: center;
  border: 1px solid @border-color;
  background: #f8f8f8;
}
.timeline-item .content, .timeline-item .content-news{
  border-left: none;
  border-top: 1px solid @border-color;
  padding-top: 5px;
}

.stat-list > li{
   margin-top: 10px;
   margin-button: 5px;
}


.chart-carmax-color{
  background-color: rgba(248,172,89,1);
  color:#fff;
}
.chart-cod-color{
  background-color: rgba(28,132,198,1);
  color:#fff;
}

.chart-iaa-color{
  background-color: rgba(237,85,101,1);
  color:#fff;
}
.chart-adesa-color{
  background-color: rgba(35,198,200,1);
  color:#fff;
}

.checkbox_boots {
  padding-left: 40px; }
.checkbox_boots label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
  font-weight: lighter;
}

.checkbox_boots label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
.checkbox_boots label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555; }
.checkbox_boots input[type="checkbox"] {
  opacity: 0; }
.checkbox_boots input[type="checkbox"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }
.checkbox_boots input[type="checkbox"]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\f00c"; }
.checkbox_boots input[type="checkbox"]:disabled + label {
  opacity: 0.65; }
.checkbox_boots input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed; }
.checkbox_boots.checkbox-circle label::before {
  border-radius: 50%; }
.checkbox_boots.checkbox-inline {
  margin-top: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #428bca;
  border-color: #428bca; }
.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f; }
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de; }
.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e; }
.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c; }
.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff; }

.high_light_result{
   background-color: yellow;
}