.sok-new-order {
  margin-top: 10px;
}
.status-bar {
  min-height: 80px;
  padding: 0px;
  margin-left: -5px;
}
.legend-table {
  padding-bottom: 10px;
}
.well-status {
  padding-left: 0px;
  padding-right: 0px;
}