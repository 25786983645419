@media print {
  .content {
    margin-top: 0px;
    padding-top: 0px;
  }
  .container{
    width: 100%;
  }
  .invoice-from-to{
    margin-bottom: 0;
  }
  .invoice-from-to .name{
    margin-top: 0px;
  }
  .invoice-header{
    margin-bottom: 25px;
  }
  .billed-to{
    padding-top: 15px;
  }
  .printer-size{
    font-size: 15px;
  }
  .invoice-logo{
    padding-bottom: 5px;
    width: 140px;
  }
  .page-break{
    display: block; page-break-before: always;
  }
  .ibox-title{
      border: 0px;
   }
  .gray-bg{
    background-color: #ffffff;
  }
  .supervisor-sig{
    max-width: 150px !important;
  }
  #page-wrapper{
    min-height: 0px !important;
  }
  .code-value{
    padding-left: 45px;
  }
  .no-padding-print{
    padding: 0 !important;
  }
  .no-margins-print{
    margin: 0 !important;
  }
  .wrapper.wrapper-content{
    padding-top: 0px !important;
  }
  .ibox-content{
    border-width: 0px;
  }
  .dl-horizontal{
    margin-left: -60px;
  }
  .print-top-15-margin{
    margin-top: -15px;
  }
  .print-top-50-margin{
    margin-top: -50px;
  }
  address{
    margin-bottom: 10px;
  }
  .hr-line-dashed{
    margin-top: 10px;
    margin-button: 10px;
  }

  /*Help*/
  #print-controls{
    display: none;
  }
  .mail-box-header{
    border: none;
    border-bottom: 2px solid #e7eaec;
  }
  #rich_text_description{
    padding-top: 20px;
  }

  #bill_information_in_statements{
    padding-right: 30px;
    table{
      font-size: 12px;
    }
  }
  .replenishment_table{
    margin: 0 20px;
  }
  .top-print-xs{
    margin-top: 5px;
  }
  .top-print-sm{
    margin-top: 10px;
  }
  .top-print-md{
    margin-top: 15px;
  }
  .top-print-lg{
    margin-top: 20px;
  }

  .break_print_page{
    display: block !important;
    page-break-before: always !important;
    position: relative;
  }
  .lateral_padding_print{
    padding-left: 20px !important;
    padding-right: 30px !important;

    td{
      padding: 2px !important;
    }
  }

  /*th.background-th:before,
  th.background-th:after {

  }*/
  .invoice_print_blue tr,.invoice_print_blue th {

       background: #0a6aa1 !important;
       color: #ffffff !important; // Black prints faster: h5bp.com/s
       -webkit-print-color-adjust: exact !important;
  }

  /*td,tr,*,
  *:before,
  *:after {
    background: #0a6aa1 !important;
    color: #00ff00 !important; // Black prints faster: h5bp.com/s
    -webkit-print-color-adjust: exact;
  }*/

  /*Redefining print to take sm dimensions*/
  .make-grid(sm);

  .visible-xs {
    .responsive-invisibility();
  }

  .hidden-xs {
    .responsive-visibility();
  }

  .hidden-xs.hidden-print {
    .responsive-invisibility();
  }

  .hidden-sm {
    .responsive-invisibility();
  }

  .visible-sm {
    .responsive-visibility();
  }
}