.file {
  background-color: #ffffff;
  border: 1px solid #e7eaec;
  margin-bottom: 20px;
  margin-right: 20px;
  position: relative;
  padding-top: 5px;
}

.file .icon, .file .image {
  height: 100px;
  overflow: hidden;
}
.file .icon, .file .image img {
  max-width: 100px;
}
.file .file-name {
  background-color: #f8f8f8;
  border-top: 1px solid #e7eaec;
  padding: 10px;
  font-weight: bold;
  text-align: center;
}
.corner {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: transparent #f1f1f1 #f1f1f1 transparent;
  border-image: none;
  border-style: solid;
  border-width: 0.6em;
  bottom: 0;
  display: inline-block;
  height: 0;
  line-height: 0;
  position: absolute;
  right: 0;
  width: 0;
}
.view-box {
  min-height: 247px !important;
}

.has-error .select2-selection.select2-selection--single {
  border-color: #ed5565;
}
.select2-selection.select2-selection--multiple {
  min-height: 28px;
  .select2-selection__choice, .select2-search__field {
    margin-top: 3px;
  }
}
.generic-tk-label {
  position: absolute;
  text-align: center;
  width: 55%;
  top: 25%;
  font-size: large;
}
.sm-5-tk-label {
  position: absolute;
  text-align: center;
  width: 35%;
  top: 30%;
  font-size: large;
}
.proforma {
  .equipment-img {
    width: 60px;
    max-height: 60px;
  }
  tr.asset-missing {
    background-color: #f2dede !important;
  }

}
.form-inline-block {
  display: inline-block;
}
.btn-proforma{
  width: 100%;
}
#selected_proforma_modal_table {
  max-height: 300px !important;
  overflow-y: auto;
}

#selected_proforma_modal {
  min-height:320px;
  small.col-sm-4 {
    border-left: solid 2px beige;
  }
  small.col-sm-4:first-child {
    border-left: none;
  }
}
#request_equipment_approval_modal {
  select {
    width: 100%;
    padding: 5px;
  }
}

.truck-list > a {
  color: #337ab7;
}

.equipment_name {
  display: block;
}

#recent_routes_processing {
  display: block;
  position: absolute;
  left: 429px;
  top: 155px;
  text-align: center;
}
.recent-routes-datatable {
  min-height: 550px;
  height: 100%;
}

.marginb-100 {
  margin-bottom: -100px;
}

.paddingb100 {
  padding-bottom: 100px;
}
