.list-none {
  list-style: none;
}


.model-list-element {
  margin-top: 10px;
  border-bottom: solid 1px #c2b5ba;
}

.header-buttons-row {
  padding: 0 10px 0 10px;
}

.padding-5 {
  padding: 5px;
}
.back-icon {
  margin-right: 5px;
}

.inline-block {
  display: inline-block;
}

.year-list {
  border: solid 1px #dedede;
  border-radius: 10px;
  height:250px;
  min-height: 180px;
  padding: 5px;
  overflow-y: scroll;
}

.category-arrow:hover{
  color:#1ab394;
}
