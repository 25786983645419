div.dataTables_length label {
  float: left;
  text-align: left;
  font-weight: normal;
}

div.dataTables_length select {
  width: 75px;
  margin: 10px;
}

div.dataTables_filter label {
  float: right;
  font-weight: normal;
}

div.dataTables_filter input {
  width: 16em;
  margin: 10px;
}

div.dataTables_info {
  padding-top: 8px;
}

div.dataTables_paginate {
  float: right;
  margin: 0;
}

div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
}

table.dataTable,
table.dataTable td,
table.dataTable th {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
}

/* DATATABLES */
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background: transparent;
}
table.dataTable thead .sorting_asc:after {
  float: right;
  content: "\f0de";
  font-family: fontawesome;
}
table.dataTable thead .sorting_desc:after {
  content: "\f0dd";
  float: right;
  font-family: fontawesome;
}
table.dataTable thead .sorting:after {
  content: "\f0dc";
  float: right;
  font-family: fontawesome;
  color: rgba(50, 50, 50, 0.5);
}
.dataTables_wrapper {
  padding-bottom: 30px;
}



table.dataTable th:active {
  outline: none;
}

/* Scrolling */

div.dataTables_scrollHead table {
  margin-bottom: 0 !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

div.dataTables_scrollHead table thead tr:last-child th:first-child,
div.dataTables_scrollHead table thead tr:last-child td:first-child {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

div.dataTables_scrollBody table {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-top: none;
}

div.dataTables_scrollBody tbody tr:first-child th,
div.dataTables_scrollBody tbody tr:first-child td {
  border-top: none;
}

div.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none;
}

/*
 * TableTools styles
 */

.table tbody tr.active td,
.table tbody tr.active th {
  color: white;
  background-color: #08C;
}

.table tbody tr.active:hover td,
.table tbody tr.active:hover th {
  background-color: #0075b0 !important;
}

.table tbody tr.active a {
  color: white;
}

.table-striped tbody tr.active:nth-child(odd) td,
.table-striped tbody tr.active:nth-child(odd) th {
  background-color: #017ebc;
}

table.DTTT_selectable tbody tr {
  cursor: pointer;
}

div.DTTT .btn {
  font-size: 12px;
  color: #333 !important;
}

div.DTTT .btn:hover {
  text-decoration: none !important;
}

ul.DTTT_dropdown.dropdown-menu {
  z-index: 2003;
}

ul.DTTT_dropdown.dropdown-menu a {
  color: #333 !important; /* needed only when demo_page.css is included */
}

ul.DTTT_dropdown.dropdown-menu li {
  position: relative;
}

ul.DTTT_dropdown.dropdown-menu li:hover a {
  color: white !important;
  background-color: #0088cc;
}

div.DTTT_collection_background {
  z-index: 2002;
}

/* TableTools information display */

div.DTTT_print_info.modal {
  height: 150px;
  margin-top: -75px;
  text-align: center;
}

div.DTTT_print_info h6 {
  margin: 1em;
  font-size: 28px;
  font-weight: normal;
  line-height: 28px;
}

div.DTTT_print_info p {
  font-size: 14px;
  line-height: 20px;
}

/*
 * FixedColumns styles
 */

div.DTFC_LeftHeadWrapper table,
div.DTFC_LeftFootWrapper table,
div.DTFC_RightHeadWrapper table,
div.DTFC_RightFootWrapper table,
table.DTFC_Cloned tr.even {
  background-color: white;
}

div.DTFC_RightHeadWrapper table,
div.DTFC_LeftHeadWrapper table {
  margin-bottom: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

div.DTFC_RightHeadWrapper table thead tr:last-child th:first-child,
div.DTFC_RightHeadWrapper table thead tr:last-child td:first-child,
div.DTFC_LeftHeadWrapper table thead tr:last-child th:first-child,
div.DTFC_LeftHeadWrapper table thead tr:last-child td:first-child {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

div.DTFC_RightBodyWrapper table,
div.DTFC_LeftBodyWrapper table {
  margin-bottom: 0 !important;
  border-top: none;
}

div.DTFC_RightBodyWrapper tbody tr:first-child th,
div.DTFC_RightBodyWrapper tbody tr:first-child td,
div.DTFC_LeftBodyWrapper tbody tr:first-child th,
div.DTFC_LeftBodyWrapper tbody tr:first-child td {
  border-top: none;
}

div.DTFC_RightFootWrapper table,
div.DTFC_LeftFootWrapper table {
  border-top: none;
}
table.dataTable.dtr-inline.collapsed tbody td:first-child,
table.dataTable.dtr-inline.collapsed tbody th:first-child {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}
table.dataTable.dtr-inline.collapsed tbody td:first-child:before,
table.dataTable.dtr-inline.collapsed tbody th:first-child:before {
  top: 8px;
  left: 4px;
  height: 16px;
  width: 16px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 16px;
  text-align: center;
  line-height: 14px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  content: '+';
  background-color: #31b131;
}
table.dataTable.dtr-inline.collapsed tbody td:first-child.dataTables_empty:before,
table.dataTable.dtr-inline.collapsed tbody th:first-child.dataTables_empty:before {
  display: none;
}
table.dataTable.dtr-inline.collapsed tbody tr.parent td:first-child:before,
table.dataTable.dtr-inline.collapsed tbody tr.parent th:first-child:before {
  content: '-';
  background-color: #d33333;
}
table.dataTable.dtr-inline.collapsed tbody tr.child td:before {
  display: none;
}
table.dataTable.dtr-column tbody td.control,
table.dataTable.dtr-column tbody th.control {
  position: relative;
  cursor: pointer;
}
table.dataTable.dtr-column tbody td.control:before,
table.dataTable.dtr-column tbody th.control:before {
  top: 50%;
  left: 50%;
  height: 16px;
  width: 16px;
  margin-top: -10px;
  margin-left: -10px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 16px;
  text-align: center;
  line-height: 14px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  content: '+';
  background-color: #31b131;
}
table.dataTable.dtr-column tbody tr.parent td.control:before,
table.dataTable.dtr-column tbody tr.parent th.control:before {
  content: '-';
  background-color: #d33333;
}
table.dataTable tr.child {
  padding: 0.5em 1em;
}
table.dataTable tr.child:hover {
  background: transparent !important;
}
table.dataTable tr.child ul {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
table.dataTable tr.child ul li {
  border-bottom: 1px solid #efefef;
  padding: 0.5em 0;
}
table.dataTable tr.child ul li:first-child {
  padding-top: 0;
}
table.dataTable tr.child ul li:last-child {
  border-bottom: none;
}
table.dataTable tr.child span.dtr-title {
  display: inline-block;
  min-width: 75px;
  font-weight: bold;
}
