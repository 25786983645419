@media (min-width: @screen-sm-min) {

  .wrapper-content {
    padding-top: 72px;
  }

  .modal-dialog {
    margin-top: 82px;
  }

  .modal-medium-small {
    width: 400px;
  }
  .col-sm-for-5{
    width: 20%;
    float: left;
  }
  /* Intimeline */
  .timeline-item .date i {
    right: 0;
    left: auto;
    border-top: 1px solid @border-color;
    border-bottom: 1px solid @border-color;
    border-left: 1px solid @border-color;
  }
  .timeline-item .date {
    text-align: right;
  }

  .timeline-item .content, .timeline-item .content-news {
    padding-top: 10px;
    border-left: 1px solid @border-color;
  }

  .timeline-item .content:hover,.timeline-item .content-news:hover {
    background: #f6f6f6;
  }
  /*
    Vehicle Search Code
  */
  .items-images{
    margin-top: 35px;
  }
  .items-images img{
    max-height: 300px;
  }
  div.bhoechie-tab-content{
    padding-left: 20px;
  }


 /**
   Table-Header Fix
 */
  .fht-table-wrapper .fht-fixed-body .fht-tbody,
  .fht-table-wrapper .fht-tbody {
    /* appearance */
    overflow: auto;
  }
  /**
  * give style to convert tabs to accordion
  */
  .customer-view, .department-view, .user-view, .route-view, .vehicle-search-box, .tabs_collapse_convertion {
    .visible-tabs {
      display: block !important;
    }
    .hidden-tabs {
      display: none !important;
    }
  }
  /*Restore element lists height in desktop*/
  .stores-list, .users-list, .route-list, .departments-list{
    .list-group {
      height: 100%;
      overflow: inherit;
    }
  }
  /*Redefine classes for more than 786px width*/
  .filter_button{
    margin-top: 24px;
    height: 28px;
    line-height: 1;
  }
  .top-mobile-xs, .top-mobile-sm, .top-mobile-md, .top-mobile-lg{
    margin-top: auto;
  }
  .text-mobile-center{
    text-align: inherit;
  }
  .center-mobile{
    margin: auto;
  }
  .contact-box{
    padding: 5px;
    .user-images{
      max-height: 80px;
    }
    p small{
      max-width: 85%;
      display: inline-block;
      padding-left: 2px;
    }
  }
  .jq-toast-wrap.top-right { top: 20px; right: 40px; }

  .wsmenu-list .megamenu.quaterdiv{
    width: 300px;
  }

  .megamenu ul li.title{
    background-color: transparent;
    color: #666666;
  }

  .equipment_name{
    display: inherit;
  }

  .panel-body{
    padding: 15px;
  }
  .modal-body{
    padding: 20px 30px 10px 30px;
  }
  .ibox-content{
    padding: 15px 20px 20px 20px;
    /*padding: 5px;*/
  }
  .invoice-buttons {
    button, a{
      padding: 6px 12px;
    }
  }
}

