.modal-comment-container {
  width: 700px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.test-scroll .tt-menu{
  max-height: 800px;
  overflow-y: auto;
}
@media screen and (max-height: 958px) and (min-height: 660px) {
  .test-scroll .tt-menu{
    max-height: 500px;
  }
}
@media screen and (max-height: 660px) and (min-height: 400px) {
  .test-scroll .tt-menu{
    max-height: 300px;
  }
}
@media screen and (max-height: 400px) and (min-height: 150px) {
  .test-scroll .tt-menu{
    max-height: 150px;
  }
}


#custom-search-input{
  padding: 3px;
  border: solid 1px #E4E4E4;
  border-radius: 6px;
  background-color: #fff;
}

#custom-search-input input{
  border: 0;
  box-shadow: none;
}

#custom-search-input button{
  margin: 2px 0 0 0;
  background: none;
  box-shadow: none;
  border: 0;
  color: #666666;
  padding: 0 8px 0 10px;
  border-left: solid 1px #ccc;
}

#custom-search-input button:hover{
  border: 0;
  box-shadow: none;
  border-left: solid 1px #ccc;
}

#custom-search-input .glyphicon-search{
  font-size: 23px;
}

.working-class{
  background-color: #dbffdd !important;
}

.background_selected {
  background: #a2efef;
}

