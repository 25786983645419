/* Variables
================================================== */

    @pg-highlight-color: #1c84c6;

/* Mixins
================================================== */

    .pg-rounded-corners (@radius: 5px) {
        -webkit-border-radius: @radius;
        -moz-border-radius: @radius;
        -o-border-radius: @radius;
        border-radius: @radius;
    }

    .pg-transition-animation (@prop: all, @time: 1s, @ease: linear) {
        -webkit-transition: @prop @time @ease;
        -moz-transition: @prop @time @ease;
        -o-transition: @prop @time @ease;
        -ms-transition: @prop @time @ease;
        transition: @prop @time @ease;
    }

    .pg-icon-replacement (@imgtouse: "pageguide.png", @height: 20px, @width: 20px, @bgposition: 0 0) {
        background: transparent url(@imgtouse) @bgposition no-repeat;
        display: block;
        height: @height;
        overflow: hidden;
        text-indent: -999px;
        width: @width;
    }

    .pg-box-shadow (@x: 0, @y: 0, @blur: 5px, @spread: 0, @color: rgba(0, 0, 0, 0.5)) {
        -webkit-box-shadow: @arguments;
        -moz-box-shadow: @arguments;
        -o-box-shadow: @arguments;
        box-shadow: @arguments;
    }

    .pg-opacity(@op:100) {
        filter:alpha(opacity=@op);
        -moz-opacity:@op/100;
        -khtml-opacity:@op/100;
        opacity:@op/100;
    }

    .pg-transform (@rotate: 0deg, @scale: 1, @skewX: 0deg, @skewY: 0deg, @translate: 0px) {
        -webkit-transform: rotate(@rotate) scale(@scale) skewX(@skewX) skewY(@skewY) translate(@translate);
        -moz-transform: rotate(@rotate) scale(@scale) skewX(@skewX) skewY(@skewY) translate(@translate);
        -o-transform: rotate(@rotate) scale(@scale) skewX(@skewX) skewY(@skewY) translate(@translate);
        -ms-transform: rotate(@rotate) scale(@scale) skewX(@skewX) skewY(@skewY) translate(@translate);
        transform: rotate(@rotate) scale(@scale) skewX(@skewX) skewY(@skewY) translate(@translate);
    }

    .pg-border-box (){
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

/* Page Guide
================================================== */

    #tlyPageGuideWrapper {
        .pg-border-box;
        color: #000;
        font-size:12px;
        font-family: Helvetica, Arial, sans-serif;

        #tlyPageGuideToggles {
            top: 84px;
            right: 0;
            position: fixed;
            z-index: 9999;
            display: block;
        }
        .tlypageguide_toggle {
            .pg-border-box;
            background: rgba(0, 0, 0, 0.1);
            .pg-rounded-corners(3px 0 0 3px);
            clear: both;
            color: #000000;
            cursor: pointer;
            display: block;
            float: right;
            font-size: 11px;
            height: 50px;
            line-height: 25px;
            padding: 22px 6px 6px;
            margin-bottom: 20px;
            position: relative;
            right: -185px;
            text-align: left;
            text-decoration: none;
            width: 184px;
            .pg-transition-animation(all, 0.2s, ease-in);

            &:before {
                .pg-icon-replacement("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIE1hY2ludG9zaCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MjU5MjM2NTg4QkExMUUxODFFQUI0QjhGOEVCQTk1QSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MjU5MjM2Njg4QkExMUUxODFFQUI0QjhGOEVCQTk1QSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjgyNTkyMzYzODhCQTExRTE4MUVBQjRCOEY4RUJBOTVBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjgyNTkyMzY0ODhCQTExRTE4MUVBQjRCOEY4RUJBOTVBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+NYhnaAAAAOJJREFUeNpiZAACQ0PDeiDVwEA6aGDU1dXlZ2Rk/MBAJmABEX///mVYsGAByZoTEhIgDA0Njf/kAJA+JpgLyAEgfWAv/PnzByxw5swZuKSJiQkKHxmA5GD6KHYBGEhLS5MVBiB9LMgmoXsBBnCJg/RRxwtCQkJkeQGkjxFkAB8f339yUyIziGBlZWX49++fA9BQBlIwOC8gm8bFxWUPYwMNBKVTAygG5ZUNTExM8PT+7du3g7gzCAtLPNBV59nY2PiB9H8g9gfxQeJE+Qto035mZmZ+KPs/EOuD+CBxdLUAAQYA7/EQmFgcNYsAAAAASUVORK5CYII=", 16px, 16px, 0 0);
                content: " ";
                left: 25px;
                position: absolute;
                top: 8px;
            }

            div {
                .pg-border-box;
                border-left: 1px solid #fff;
                display: block;
                height: 33px;
                line-height: 16px;
                overflow: hidden;
                padding: 2px 0 0 5px;
                position: absolute;
                right: -4px;
                top: 6px;
                width: 124px;

                span {
                    display: block;
                    margin: 0 0 0 -118px;
                    width: 115px;
                    .pg-transition-animation(all, 0.2s, ease-in);
                }
            }

            a {
                bottom: -26px;
                color: #000;
                opacity: 0;
                position: absolute;
                right: 10px;
                text-decoration: none;
            }

            &:hover {
                background: rgba(0, 0, 0, 0.2);
                right: 0 !important;

                div span {
                    margin: 0;
                }
            }
        }

        #tlyPageGuideMessages {
            .pg-rounded-corners(3px 3px 0 0);
            background: rgba(0, 0, 0, 0.85);
            .pg-box-shadow(0, 0, 9px, 4px, rgba(0, 0, 0, 0.4));
            bottom: 0;
            display: none;
            font-size: 16px;
            height: 0;
            margin: 0 5% 0 5%;
            padding: 10px;
            position: fixed;
            width: 90%;
            z-index: 1000;
            overflow-y: auto;
            span {
                background: fade(@pg-highlight-color, 95%);
                .pg-rounded-corners(3px);
                color: #fff;
                display: block;
                font-size: 32px;
                height: 46px;
                left: 40px;
                line-height: 50px;
                overflow: hidden;
                position: absolute;
                text-align: center;
                top: 25px;
                width: 50px;
            }

            .tlypageguide_text {
                color: #fff;
                line-height: 22px;
                margin: 10px 90px 0 120px;
                position: relative;

                a {
                    color: #fff;

                    &:hover {
                        color: #fff;
                    }
                }
            }

            .tlypageguide_close {
                background: @pg-highlight-color;
                .pg-rounded-corners(0 0 3px 3px);
                color: #fff;
                display: block;
                font-size: 11px;
                height: 24px;
                line-height: 26px;
                overflow: hidden;
                position: absolute;
                right: 50px;
                text-align: center;
                text-decoration: none;
                top: 0;
                width: 50px;
            }

            .tlypageguide_back,
            .tlypageguide_fwd {
                .pg-icon-replacement ("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAVCAYAAAByrA+0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIE1hY2ludG9zaCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MjU5MjM2OTg4QkExMUUxODFFQUI0QjhGOEVCQTk1QSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MjU5MjM2QTg4QkExMUUxODFFQUI0QjhGOEVCQTk1QSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjgyNTkyMzY3ODhCQTExRTE4MUVBQjRCOEY4RUJBOTVBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjgyNTkyMzY4ODhCQTExRTE4MUVBQjRCOEY4RUJBOTVBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+eySFcAAAAKRJREFUeNqMk1ENhDAQROfjDNQCFs5CLWABC5wELGABCyChFrBQCz2aQELKvtImk5CyLxtmBqWUlmSf7pBK6XwRDWAlIGuELT0BWbsB5DtHgIctIwECA+LdgBJwYMBCQM0AT0BWAAMQQAMIoC2BhgcK0hp2tapYwFQrYzn8fUu75UPjvU8tgQ1Wl1zrP3E9zLU6lAClOlsZfST5Q5ue52fc6S/AABXAQWeCQoWGAAAAAElFTkSuQmCC", 30px, 30px, 10px 4px);
                position: absolute;
                top: 34px;
                left: 10px;
            }

            .tlypageguide_back {
                .pg-transform(-180deg, 1, 0, 0, 0);
            }

            .tlypageguide_fwd {
                left: 90px;
            }
        }

        #tlyPageGuideContent {
            display: none;
        }
    }

    .tlypageguide-open {

        #tlyPageGuideWrapper {
            .tlypageguide_toggle.tlyPageGuideToggleActive {
                .pg-box-shadow(6px, 4px, 5px, 0, rgba(0, 0, 0, 0.4));
                background: fade(@pg-highlight-color, 90%);
                color: #fff;
                right: 0 !important;

                a {
                    opacity: 1.0;
                }

                div span {
                    margin: 0;
                }
            }

            #tlyPageGuideContent {
                display: block;
            }
        }
    }

    .tlyPageGuideWelcome, #tlyPageGuideWelcome {
        display: none;
        .pg-opacity(0);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        width: 95%;
        margin: auto;
        max-width: 400px;
        height: 300px;
        max-height: 80%;
        background: rgba(0, 0, 0, 0.85);
        padding: 15px 20px;
        color: #fff;
        font-size: 16px;
        line-height: 22px;
        .pg-box-shadow(0, 0, 9px, 4px, rgba(0, 0, 0, 0.4));
        .pg-rounded-corners(10px);
        .pg-transition-animation (all, 0.2s, linear);

        &.open {
            .pg-opacity(95);
        }

        button {
            background: fade(@pg-highlight-color, 95%);
            .pg-rounded-corners(3px);
            color: #fff;
            font-size: 22px;
            border: 0;
            overflow: hidden;
            text-align: center;
            padding: 5px 10px;
            cursor: pointer;
            font-family: Helvetica, Arial, sans-serif;
        }
    }
    #tlyPageGuideOverlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 9998;
        .pg-opacity(0);
    }

    .tlyPageGuideWelcomeOpen {
        .tlyPageGuideWelcome, #tlyPageGuideWelcome {
            .pg-opacity(95);
            display: block;
        }
        #tlyPageGuideOverlay {
            .pg-opacity(70);
            display: block;
        }
    }

    // NEW

    #tlyPageGuide {
        display: none;
    }

    .tlypageguide_shadow {
        position: absolute;
        display: none;
        background-color: fade(@pg-highlight-color, 10%);
        .pg-box-shadow(0, 2px, 5px, 2px, rgba(26,123,185, 0.25));
        pointer-events: none;
    }

    .tlyPageGuideStepIndex {
        background: fade(@pg-highlight-color, 30%);
        .pg-box-shadow(1px, 2px, 10px, 1px, rgba(0, 0, 0, 0.4));
        .pg-transition-animation(~"min-height, max-height", 0.1s, ease-in-out);
        cursor: pointer;
        display: block;
        height: 46px;
        line-height: 50px;
        position: absolute;
        text-align: center;
        width: 50px;
        z-index: 50000;
        .pg-opacity(100);
        min-height: 15px;
        max-height: 46px;
        pointer-events: all;
        color: #fff;
        font-size: 32px;
        text-decoration: none;
        width: 50px;

        &:after {
            content: " ";
            display: block;
            height: 0;
            left: 0;
            position: absolute;
            top: -15px;
            width: 0;
            border-top: none;
            border-right: none;
            border-bottom: none;
            border-left: none;
            top: auto;
            left: auto;
            right: auto;
        }

        &.tlypageguide_bottom {
            bottom: -65px;
            &:after {
                border-bottom: 15px solid fade(@pg-highlight-color, 30%);
                border-right: 15px solid transparent;
                left: 0;
                top: -15px;
            }
        }

        &.tlypageguide_right {
            right: -65px;
            &:after {
                border-top: 15px solid fade(@pg-highlight-color, 30%);
                border-left: 15px solid transparent;
                left: -15px;
                top: 0;
            }
        }

        &.tlypageguide_left {
            left: -65px;
            &:after {
                border-top: 15px solid fade(@pg-highlight-color, 30%);
                border-right: 15px solid transparent;
                right: -15px;
                top: 0;
            }
        }

        &.tlypageguide_top {
            top: -60px;
            &:after {
                border-top: 15px solid fade(@pg-highlight-color, 30%);
                border-right: 15px solid transparent;
                bottom: -15px;
                left: 0;
            }
        }

        &:hover {
            background: fade(@pg-highlight-color, 95%);

            &.tlypageguide_bottom:after {
                border-bottom: 15px solid fade(@pg-highlight-color, 95%);
            }

            &.tlypageguide_right,
            &.tlypageguide_left,
            &.tlypageguide_top {
                &:after {
                    border-top: 15px solid fade(@pg-highlight-color, 95%);
                }
            }
        }
    }

    .tlypageguide-active {

        // highlight the index bubble
        .tlyPageGuideStepIndex {
            background: fade(@pg-highlight-color, 95%);

            &.tlypageguide_bottom:after {
                border-bottom: 15px solid fade(@pg-highlight-color, 95%);
            }

            &.tlypageguide_right,
            &.tlypageguide_left,
            &.tlypageguide_top {
                &:after {
                    border-top: 15px solid fade(@pg-highlight-color, 95%);
                }
            }
        }
    }
