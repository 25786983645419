@brand-secondary:       #eaf2f8;//#789fba;
@brand-secondary-text:  #000;

@btn-secondary-color:               @brand-secondary-text;
@btn-secondary-bg:                  @brand-secondary;
@btn-secondary-border:              darken(@brand-secondary, 10%);

@state-secondary-text:              @brand-secondary-text;
@state-secondary-bg:                @brand-secondary;
@state-secondary-border:            darken(@brand-secondary, 5%);

@label-secondary-bg:             @brand-secondary;

@alert-secondary-bg:             @state-secondary-bg;
@alert-secondary-text:           @state-secondary-text;
@alert-secondary-border:         @state-secondary-border;

@progress-bar-secondary-bg:      @brand-secondary;

@panel-secondary-text:           @brand-secondary-text;
@panel-secondary-border:         @state-secondary-border;
@panel-secondary-heading-bg:     @state-secondary-bg;

.panel-secondary {
  .panel-variant(@panel-secondary-border; @panel-secondary-text; @panel-secondary-heading-bg; @panel-secondary-border);
}

.alert-secondary {
  .alert-variant(@alert-secondary-bg; @alert-secondary-border; @alert-secondary-text);
}

.btn-secondary {
  .button-variant(@btn-secondary-color; @btn-secondary-bg; @btn-secondary-border);
}

.label-secondary {
  .label-variant(@label-secondary-bg);
}

.progress-bar-secondary {
  .progress-bar-variant(@progress-bar-secondary-bg);
}

/*#e6e6e6 - #d9d9d9

#e1ebf5 - #c6d9ec

#c9d7e5

#d5dbdb

#d5d8dc

#eaf2f8 - #c6dbec*/