/*
Inspired by http://dribbble.com/shots/890759-Ui-Kit-Metro/attachments/97174
*/

.app-selected {
    background-color: #e3e5eb;
}

.click-able {
    cursor: pointer;
}
.system-selected{
    color:darkgreen;
}
.system-inactive{
    color:darkred;
}
.client-token{
    padding:1px;
    margin-bottom: 2px;
}
.card-apps-div {
    .status {
        background: #fff none repeat scroll 0 0;
        border: solid 1px #86888e;
        cursor: pointer;
    }
}
.status-success{
    color: #1cc09f;
}
.status-danger{
    color: #ed5565;
}
.card-app-info{
    cursor:pointer;
}
.app_card{
    -webkit-box-shadow: 0px 0px 49px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 49px -5px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 9px -5px rgba(0,0,0,0.75);

    padding-top: 0px;
    padding-right: 10px;
    border-radius: 5px;
}
.button-close{
    border-bottom-width: 0px;
    padding-bottom: 8px;
}



