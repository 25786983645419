.bs-wizard {
  border-bottom: solid 1px #a3d6f7;
  padding: 0 0 10px 0;
  width: 100%;

  & > .bs-wizard-step {
    padding: 0;
    position: relative;

    .bs-wizard-stepnum {
      color: #595959;
      font-size: 14px;
      margin-bottom: 5px;
      font-weight: 700
    }

    .bs-wizard-info {
      color: #999;
      font-size: 12px;
    }

    & > .bs-wizard-dot {
      position: absolute;
      width: 30px;
      height: 30px;
      display: block;
      background: #43aef2;
      top: 24px;
      left: 50%;
      margin-top: -15px;
      margin-left: -15px;
      border-radius: 50%;
    }

    &.complete {
      & > .bs-wizard-dot {
        &:after {
          content: ' ';
          width: 14px;
          height: 14px;
          background: #1c84c6;
          border-radius: 50px;
          position: absolute;
          top: 8px;
          left: 8px;
        }
      }

      & > .progress {
        & > .progress-bar {
          width: 100%;
        }
      }
    }

    &.active {
      & > .progress {
        & > .progress-bar {
          width: 50%;
        }
      }
    }

    & > .bs-wizard-dot {
      i {
        color: #fff;
        margin-left: 9px;
        margin-top: 8px;
      }
    }

    & > .progress {
      position: relative;
      border-radius: 0px;
      height: 8px;
      box-shadow: none;
      margin: 20px 0;

      & > .progress-bar {
        width: 0px;
        box-shadow: none;
        background: #43aef2;
      }

    }

    &:first-child {
      &.active {
        & > .progress {
          & .progress-bar {
            width: 0%;
          }
        }
      }

      & > .progress {
        left: 50%;
        width: 50%;
      }
    }

    &:last-child {
      &.active {
        & > .progress {
          & .progress-bar {
            width: 100%;
          }
        }
      }

      & > .progress {
        width: 50%;
      }
    }

    &.disabled {
      & > .bs-wizard-dot {
        background-color: #f5f5f5;
      }

      &:after {
        opacity: 0;
      }

      a {
        &.bs-wizard-dot {
          pointer-events: none;
        }
      }
    }
  }
}