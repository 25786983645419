// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&amp;lang=en");

// Variables, Mixins
@import "variables.less";
@import "mixins.less";

// INSPINIA Theme Elements
@import "typography.less";
//@import "top_navigation.less";
@import "buttons.less";
@import "badgets_labels.less";
@import "elements.less";
//@import "sidebar.less";
@import "base.less";
@import "icheck.less";

//@import "notifications.less";
@import "animate.less";
@import "chosen.less";
//@import "pages.less";
// RTL Support
//@import "rtl.less";
// INSPINIA Skins
//@import "skins.less";
// Media query style

@import "media.less";










