/* CONTACTS */
.contact-box {
  background-color: #ffffff;
  border: 1px solid @border-color;
  padding: 20px;
  margin-bottom: 20px;
  float: left;
  width: 100%;
}
.contact-box a {
  color: inherit;
}