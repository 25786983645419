.file-box {
  float: left;
  width: 220px;
}
.file-manager h5 {
  text-transform: uppercase;
}

.file-manager {
  list-style: none outside none;
  margin: 0;
  padding: 0;
}

.folder-list li {
  border-bottom: 1px solid @border-color;
  display: block;
  padding: 10px 0;
  color: #666666;
}
.folder-list li .title-emails,
.folder-list li .title-allow-users{
  cursor: pointer;
}
.folder-list li .title-emails:hover,
.folder-list li .title-allow-users:hover{
  text-decoration: underline;
}
.folder-list li i {
  margin-right: 8px;
  color: #3d4d5d;
}

.file-manager .hr-line-dashed {
  margin: 15px 0;
}


/*EMAILS TEMPLATE*/
.body-wrap {
  background-color: #f6f6f6;
  width: 100%;
}
.container-email {
  clear: both !important;
  display: block !important;
  margin: 0 auto !important;
  max-width: 600px !important;
}
.content-email {
  display: block;
  margin: 0 auto;
  max-width: 600px;
  padding: 20px;
}
.equipment_req{
  .equipment_file{
    border: solid 1px #e7eaec;
    margin-bottom: 10px;
    .file-name {
      background-color: #f8f8f8;
      border-top: 1px solid #e7eaec;
      padding: 10px;
      font-weight: bold;
      text-align: center;
    }
    .image{
      height: 100px;
      text-align: center;
    }
    .image img{
      max-height: 100%;
      max-width:100%;
    }
    .file-name-title{
      margin-top: 0;
    }
  }

}
